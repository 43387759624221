import React, { useState, useEffect } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidthResize from 'src/hooks/window/useWidth'
import { WIDTH_XL } from 'src/utils/breakpoints'
import Popup from 'src/components/Popup'

import pageContext from './pageContext.json'

import Hero from '../emprestimo-consignado-publico/sections/Hero/_Hero'
import WhatAreYouLookingFor from '../emprestimo-consignado-publico/sections/what-are-you-looking-for/_index'
import CuideDaSaude from '../emprestimo-consignado-publico/sections/CuideDaSaude/_CuideDaSaude'
import CarouselNovidades from '../emprestimo-consignado-publico/sections/CarouselNovidades/_CarouselNovidades'
import Portabilidade from '../emprestimo-consignado-publico/sections/Portabilidade/_Portabilidade'
import AtendimentoPersonalizado from '../emprestimo-consignado-publico/sections/AtendimentoPersonalizado/_AtendimentoPersonalizado'
import TipoCredito from '../emprestimo-consignado-publico/sections/TipoCredito/_TipoCredito'
import AlcanceObjetivos from '../emprestimo-consignado-publico/sections/AlcanceObjetivos/_AlcanceObjetivos'
import PedirConsignado from '../emprestimo-consignado-publico/sections/PedirConsignado/_PedirConsignado'
import VantagensEmprestimo from '../emprestimo-consignado-publico/sections/VantagensEmprestimo/_VantagensEmprestimo'
import CreditoProtegido from '../emprestimo-consignado-publico/sections/CreditoProtegido/_CreditoProtegido'
import GanhePorAmigoIndicado from '../emprestimo-consignado-publico/sections/GanhePorAmigoIndicado/_GanhePorAmigoIndicado'
import Faq from '../emprestimo-consignado-publico/sections/Faq/_Faq'
import MensageAlerta from '../emprestimo-consignado-publico/sections/MensageAlerta/_MensageAlerta'

import { Wrapper } from '../emprestimo-consignado-publico/style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const EmprestimoConsignadoPublico = () => {
  const windowWidth = useWidthResize(200)
  const domReady = useDomReady()
  const isDesktop = windowWidth > WIDTH_XL
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-emprestimo', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup closeModal={() => setIsPopupOpen(false)} setPopup={setPopup} pageName='emprestimo' />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {PopupExitPage}
        <Hero sendDatalayerEvent={sendDatalayerEvent} />
        <WhatAreYouLookingFor sendDatalayerEvent={sendDatalayerEvent} />
        <CuideDaSaude section='dobra_3' />
        <CarouselNovidades sendDatalayerEvent={sendDatalayerEvent} section='dobra_4' />
        <Portabilidade sendDatalayerEvent={sendDatalayerEvent} />
        <AtendimentoPersonalizado sendDatalayerEvent={sendDatalayerEvent} />
        <TipoCredito sendDatalayerEvent={sendDatalayerEvent} />
        <AlcanceObjetivos />
        <PedirConsignado sendDatalayerEvent={sendDatalayerEvent} />
        <VantagensEmprestimo />
        <CreditoProtegido sendDatalayerEvent={sendDatalayerEvent} section='dobra_11' />
        <GanhePorAmigoIndicado sendDatalayerEvent={sendDatalayerEvent} />
        <Faq faq={pageContext.structuredData.faq} />
        <MensageAlerta sendDatalayerEvent={sendDatalayerEvent} section='dobra_14' />
      </Layout>
    </Wrapper>
  )
}

export default EmprestimoConsignadoPublico
