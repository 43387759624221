import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    min-height: 399.81px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 480px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 544px;
  }
`

export const Card = styled.div`
  width: 264px;
  height: 227px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};
  background: ${grayscale[100]};
  padding: 24px 19px;

  @media ${device.tablet} {
    width: 293.53px;
    height: 225.67px;
  }

  @media ${device.desktopLG} {
    width: 359px;
    height: 276px;
  }

  @media ${device.desktopXXXL} {
    width: 467px;
    height: 276px;
  }
`

export const Title = styled.h2`
  display: block;

  font-family: 'Sora';
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};

  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
    padding-right: 15px;
  }
`

export const CardTitle = styled.span`
  display: block;

  font-family: 'Sora';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};

  margin-bottom: 8px;

  @media ${device.tablet} {
    font-size: 13px;
    line-height: 16px;
  }

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const CardDescription = styled.span`
  display: block;

  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }
`
