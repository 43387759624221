import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import ScrollTo from 'src/components/ScrollTo'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import Exclusividade from '../../assets/data/exclusividade.json'
import usePageQuery from '../../pageQuery'

import { ImgSize, Section, CardCarousel } from './style'

type ExclusividadeData = {
  icon: string;
  title: string;
  description: string;
}

type DataProps = {
  section: string;
}

const WIDTH_MD = 768

const CuideDaSaude = ({ section }: DataProps) => {
  const imagesData = usePageQuery()
  const windowWidth = useWidth(300)

  return (
    <Section className='py-5 bg-white'>
      <div className='container'>
        <div className='row align-items-center mb-5'>
          <div className='col-12 col-md-8 col-lg-6 offset-lg-1 order-md-last pt-md-5'>
            <h2 className='fs-32 fs-lg-40 lh-40 lh-lg-50 text-grayscale--500'>
              Cuide da saúde. Quite suas dívidas. Invista em você.
            </h2>
            <p className='fs-18 lh-22 text-grayscale--400 mt-3'>
              Para simplificar a sua vida, o Inter uniu um Consignado que realiza seus sonhos e um Cartão Black que
              oferece maiores vantagens e economia. Tenha <span className='fw-700'>mais cashback e acesso a serviços exclusivos</span>, contratando um Consignado acima de R$ 100 mil e abrindo a sua conta Inter.
            </p>
            {windowWidth >= WIDTH_MD &&
              <ScrollTo
                to='#hero-section'
                section={section}
                sectionName='Cuide da saúde. Quite suas dívidas. Invista em você.'
                elementName='Pegue seu Consignado + Cartão Black'
                styles='btn btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none mt-md-4'
              > Pegue seu Consignado + Cartão Black
              </ScrollTo>
            }
          </div>
          <ImgSize className='col-12 col-md-4 col-lg-5 mt-4 mt-md-0'>
            <Img fluid={imagesData.cartaoBlackInvista.fluid} alt='Cartão Inter Black' />
          </ImgSize>
        </div>
        <div className='row mt-5 align-items-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h3 className='fs-32 fs-lg-40 lh-40 lh-lg-50 text-grayscale--500'>Exclusividade para quem é único: você!</h3>
            <p className='fs-18 lh-22 text-grayscale--400 mt-4'>Confira as exclusividades que só o Consignado Inter + Cartão Inter Black tem!</p>
            {windowWidth >= WIDTH_MD &&
              <ScrollTo
                to='#hero-section'
                styles='btn btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none mt-md-4'
                section={section}
                sectionName='Exclusividade para quem é único: você!'
                elementName='Pegue seu Consignado + Cartão Black'
              > Pegue seu Consignado + Cartão Black
              </ScrollTo>}
          </div>
          <div className='col-12 col-md-6 col-xl-6 offset-xl-1'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 100 }}
              md={{ items: 1, partialVisibilityGutter: 100 }}
              lg={{ items: 2, partialVisibilityGutter: 30 }}
              xl={{ items: 2, partialVisibilityGutter: 80 }}
            >
              {
                Exclusividade.map((item: ExclusividadeData, index: number) => (
                  <CardCarousel
                    key={index}
                    className='bg-grayscale--100 rounded-5 justify-content-between justify-content-md-start d-flex flex-column p-3 mr-2 mr-xl-3'
                  >
                    <OrangeIcon size='MD' color='#ff7a00' icon={item.icon} />
                    <p className='fs-16 lh-20 text-grayscale--500 fw-600 mt-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                    <p className='fs-14 lh-20 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </CardCarousel>
                ))
              }
            </DefaultCarousel>
            {windowWidth < WIDTH_MD &&
              <ScrollTo
                to='#hero-section'
                styles='btn btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none mt-4'
                section={section}
                sectionName='Exclusividade para quem é único: você!'
                elementName='Pegue seu Consignado + Cartão Black'
              > Pegue seu Consignado + Cartão Black
              </ScrollTo>}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CuideDaSaude
