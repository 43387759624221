import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
// import { grayscale, orange } from 'src/styles/colors'
import BgSectionMD from '../../assets/images/bg-section-md.png'
import BgSectionLG from '../../assets/images/bg-section-lg.png'
import BgSectionXL from '../../assets/images/bg-section-xl.png'

export const ImgSize = styled.div`  
  .gatsby-image-wrapper {
    left: -90px;
    @media ${device.tablet} {
      width: 310px;
      position: relative;
      top: -40px;
    }
    @media ${device.desktopLG} {
      width: 488px;
      top: 20px;
    }
  }
`

export const Section = styled.section`
  @media ${device.tablet} {
    background: url(${BgSectionMD}) no-repeat transparent;
    background-position: top left;
  } 
  @media ${device.desktopLG} {
    background: url(${BgSectionLG}) no-repeat transparent;
    background-position: top left;
  } 
  @media ${device.desktopXL} {
    background: url(${BgSectionXL}) no-repeat transparent;
    background-position: 0 -630px;
    background-size: auto;
  }

  .react-multiple-carousel__arrow--left {
    left: -8px;
  }
  .react-multiple-carousel__arrow--right {
    right: -10px;
  }
`

export const CardCarousel = styled.div`
  min-height: 232px;
  @media ${device.desktopXL} {
    min-height: 252px;
  }
`
