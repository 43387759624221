import React from 'react'
import useWidth from 'src/hooks/window/useWidth'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import LinkTo from 'src/components/LinkTo/LinkTo'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import data from '../../assets/data/facilidade-tranquilidade.json'

import { Section, Card, Title, CardTitle, CardDescription } from './style'

type DataProps = {
  icon: string;
  title: string;
  decription: string;
}

const WIDTH_MD = 768

const PedirConsignado = ({ sendDatalayerEvent }: DataLayerProps) => {
  const windowWidth = useWidth(300)
  return (
    <Section id='pedirConsignado' className='py-5 bg-white'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-4 col-xl-3'>
            <Title>Como pedir o crédito Consignado Inter?</Title>
            {windowWidth >= WIDTH_MD &&
              <LinkTo
                to='#hero-section'
                btncolor='btn btn-orange--extra fw-700 rounded-2 text-none mt-4'
                section='dobra_9'
                sectionName='Como pedir o crédito Consignado Inter?'
                elementName='Simule o seu Consignado'
                sendDatalayerEvent={sendDatalayerEvent}
              > Simule o seu Consignado
              </LinkTo>}
          </div>
          <div className='col-12 col-md-7 col-xl-9 pl-xl-5'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
              md={{ items: 1, partialVisibilityGutter: 85 }}
              lg={{ items: 1, partialVisibilityGutter: 155 }}
              xl={{ items: 2, partialVisibilityGutter: 18 }}
            >
              {data.map((item: DataProps) => (
                <Card key={item.title}>
                  <OrangeIcon color='#FF7A00' icon={item.icon} size='MD' />

                  <div>
                    <CardTitle>{item.title}</CardTitle>
                    <CardDescription>{item.decription}</CardDescription>
                  </div>
                </Card>
              ),
              )}
            </DefaultCarousel>
            <LinkTo
              to='#hero-section'
              btncolor='btn btn-orange--extra mt-4 fw-700 rounded-2 text-none d-md-none'
              section='dobra_9'
              sectionName='Como pedir o crédito Consignado Inter?'
              elementName='Simule o seu Consignado'
              sendDatalayerEvent={sendDatalayerEvent}
            > Simule o seu Consignado
            </LinkTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PedirConsignado
